import React from "react";

const Share = () => {
  return (
    <svg
      id="share"
      xmlns="http://www.w3.org/2000/svg"
      width="22.928"
      height="22.928"
      viewBox="0 0 22.928 22.928"
    >
      <path
        id="Path_3522"
        data-name="Path 3522"
        d="M20.294,8.151,10.263,13.166l-.642-1.281L19.652,6.87l.642,1.281Zm-.642,12.9L9.621,16.032l.642-1.281,10.031,5.016-.642,1.281Z"
        transform="translate(-3.493 -2.495)"
        fill="#6352e8"
        fillRule="evenodd"
      />
      <path
        id="Path_3523"
        data-name="Path 3523"
        d="M19.346,5.732a2.15,2.15,0,1,0-2.15-2.15A2.15,2.15,0,0,0,19.346,5.732Zm0,1.433a3.583,3.583,0,1,0-3.583-3.583A3.583,3.583,0,0,0,19.346,7.165Zm0,14.33a2.15,2.15,0,1,0-2.15-2.15A2.15,2.15,0,0,0,19.346,21.5Zm0,1.433a3.583,3.583,0,1,0-3.583-3.583A3.583,3.583,0,0,0,19.346,22.929ZM3.583,13.614a2.15,2.15,0,1,0-2.15-2.15A2.15,2.15,0,0,0,3.583,13.614Zm0,1.433A3.583,3.583,0,1,0,0,11.464a3.583,3.583,0,0,0,3.583,3.583Z"
        fill="#6352e8"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Share;
